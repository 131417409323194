import React from 'react';

import styles from '../styles.module.scss';

interface Props {
  handleChange: Function;
  formValues: any;
  handleNext: Function;
}

const Step4 = ({ handleChange, handleNext, formValues }: Props) => {
  return (
    <div>
      <form onSubmit={() => handleNext()}>
      <div className={styles.inputContainer}>
        <h3>Enrolement Agreement</h3>
        <div className={styles.inner}>
          <label>
            Child Start Date
            <input
              name="agreementChildStart"
              type="date"
              onChange={(e) => handleChange(e)}
              value={formValues && formValues.agreementChildStart || ''}
              required
            />
          </label>
          <div className={styles.fees}>
          <strong>FEES:</strong>
            <p>Before School Care:   $25 per session</p>
            <p>After School Care:      $33 per session</p>
            <p>Vacation Care:           $ 75 per day</p>
          </div>
          <p className={styles.fullWidth}>
            Please tick the days you would like your child attending
            on a regular permanent basis
          </p>
          <label className={styles.checklist}>
            <strong>Before School Care</strong>
            <label>
              <input type="checkbox" value="beforeSchoolMonday" name="Monday" onChange={(e) => handleChange(e)} />
              <span>Monday</span>
            </label>
            <label>
              <input type="checkbox" value="beforeSchoolTuesday" name="Monday" onChange={(e) => handleChange(e)} />
              <span>Tuesday</span>
            </label>
            <label>
              <input type="checkbox" value="beforeSchoolWednesday" name="Monday" onChange={(e) => handleChange(e)} />
              <span>Wednesday</span>
            </label>
            <label>
              <input type="checkbox" value="beforeSchoolThursday" name="Monday" onChange={(e) => handleChange(e)} />
              <span>Thursday</span>
            </label>
            <label>
              <input type="checkbox" value="beforeSchoolFriday" name="Monday" onChange={(e) => handleChange(e)} />
              <span>Friday</span>
            </label>
          </label>
          <label className={styles.checklist}>
            <strong>After School Care</strong>
            <label>
              <input type="checkbox" value="afterSchoolMonday" name="Monday" onChange={(e) => handleChange(e)} />
              <span>Monday</span>
            </label>
            <label>
              <input type="checkbox" value="afterSchoolTuesday" name="Monday" onChange={(e) => handleChange(e)} />
              <span>Tuesday</span>
            </label>
            <label>
              <input type="checkbox" value="afterSchoolWednesday" name="Monday" onChange={(e) => handleChange(e)} />
              <span>Wednesday</span>
            </label>
            <label>
              <input type="checkbox" value="afterSchoolThursday" name="Monday" onChange={(e) => handleChange(e)} />
              <span>Thursday</span>
            </label>
            <label>
              <input type="checkbox" value="afterSchoolFriday" name="Monday" onChange={(e) => handleChange(e)} />
              <span>Friday</span>
            </label>
          </label>
          <label className={styles.checklist}>
            <strong>Vaction Care</strong>
            <label>
              <input type="checkbox" value="vactionMonday" name="Monday" onChange={(e) => handleChange(e)} />
              <span>Monday</span>
            </label>
            <label>
              <input type="checkbox" value="vacationTuesday" name="Monday" onChange={(e) => handleChange(e)} />
              <span>Tuesday</span>
            </label>
            <label>
              <input type="checkbox" value="vacationWednesday" name="Monday" onChange={(e) => handleChange(e)} />
              <span>Wednesday</span>
            </label>
            <label>
              <input type="checkbox" value="vacationThursday" name="Monday" onChange={(e) => handleChange(e)} />
              <span>Thursday</span>
            </label>
            <label>
              <input type="checkbox" value="vacationFriday" name="Monday" onChange={(e) => handleChange(e)} />
              <span>Friday</span>
            </label>
          </label>
          <ul className={styles.list}>
            <li>If these days are available they will be confirmed by the Coordinator</li>
            <li>Fees will be applied for confirmed days. </li>
            <li>Sick or absent days will be charged at full fee.</li>
            <li>Two weeks’ notice, in writing, must be given to Coordinator for planned absences charged at half session rate.</li>
          </ul>
        </div>
        <button type="submit" className={styles.nextBtn}>Submit</button>
      </div>
      </form>
    </div>
  )
}
export default Step4;
